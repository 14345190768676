import {
	reSubthemesOgulConstants
}from "./constants"
import i18n from '../localization'
import isRu from "../../../helpers/isRu"

const initialState = {
	isSending            : false,
	currentItem          : {},
	error                : null,
	subThemesOgulChanged : null,
	itemCount            : undefined,

	//getReOrgReSubthemeOgulsList;
	reOrgReSubthemeOgulsList: null,

	//createReOrgReSubthemeOguls;
	createReOrgReSubthemeOgulsSuccess: null,

	//removeReOrgReSubthemeOguls;
	removeReOrgReSubthemeOgulsSuccess: null
}

export default function (state = initialState, action){
	switch (action.type){
		case reSubthemesOgulConstants.SEND_RESUBTHEMESOGUL_REQUEST:
			return {
				...state,
				isSending: true
			}
		case reSubthemesOgulConstants.SEND_RESUBTHEMESOGUL_SUCCESS:
			return {
				...state,
				isSending   : false,
				currentItem : action.payload
			}
		case reSubthemesOgulConstants.SEND_RESUBTHEMESOGUL_FAILURE:
			return {
				...state,
				isSending : false,
				error     : action.payload
			}
		case reSubthemesOgulConstants.CreateReSubthemeOguls.REQUEST:
		case reSubthemesOgulConstants.DeleteReSubthemeOguls.REQUEST:
		case reSubthemesOgulConstants.RestoreReSubthemeOguls.REQUEST:
		case reSubthemesOgulConstants.UpdateReSubthemeOguls.REQUEST:
			return {
				...state,
				isSending            : true,
				subThemesOgulChanged : null
			}
		case reSubthemesOgulConstants.CreateReSubthemeOguls.FAILURE:
		case reSubthemesOgulConstants.DeleteReSubthemeOguls.FAILURE:
		case reSubthemesOgulConstants.RestoreReSubthemeOguls.FAILURE:
		case reSubthemesOgulConstants.UpdateReSubthemeOguls.FAILURE:
			return {
				...state,
				isSending            : false,
				error                : action.payload,
				subThemesOgulChanged : null
			}
		case reSubthemesOgulConstants.CreateReSubthemeOguls.SUCCESS:
			return {
				...state,
				isSending            : false,
				subThemesOgulChanged : action.payload.result ? i18n.messageItemAddSuccses[isRu()] : null
			}

		case reSubthemesOgulConstants.UpdateReSubthemeOguls.SUCCESS:
			return {
				...state,
				isSending            : false,
				subThemesOgulChanged : action.payload.result ? i18n.messageItemUpdateSuccses[isRu()] : null
			}
		case reSubthemesOgulConstants.RestoreReSubthemeOguls.SUCCESS:
			return {
				...state,
				isSending            : false,
				subThemesOgulChanged : action.payload.result ? i18n.messageItemRestoreSuccses[isRu()] : null
			}
		case reSubthemesOgulConstants.DeleteReSubthemeOguls.SUCCESS:
			return {
				...state,
				isSending            : false,
				subThemesOgulChanged : action.payload.result ? i18n.messageItemDeleteSuccses[isRu()] : null
			}
		case reSubthemesOgulConstants.GetSubThemesCountByTheme.REQUEST :
			return {
				...state,
				itemCount: undefined
			}
		case reSubthemesOgulConstants.GetSubThemesCountByTheme.SUCCESS :
			return {
				...state,
				itemCount: action.payload.result
			}
		case reSubthemesOgulConstants.GetSubThemesCountByTheme.FAILURE :
			return {
				...state,
				itemCount : undefined,
				error     : action.payload
			}
		case reSubthemesOgulConstants.GetSubThemesCountByTheme.CLEAR :
			return {
				...state,
				itemCount: undefined
			}

			//getReOrgReSubthemeOgulsList;
		case reSubthemesOgulConstants.GetReOrgReSubthemeOgulsList.REQUEST :
			return {
				...state,
				isSending                : true,
				reOrgReSubthemeOgulsList : null
			}
		case reSubthemesOgulConstants.GetReOrgReSubthemeOgulsList.SUCCESS :
			return {
				...state,
				isSending                : false,
				reOrgReSubthemeOgulsList : action.payload.result
			}
		case reSubthemesOgulConstants.GetReOrgReSubthemeOgulsList.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				isSending: false
			}

			//createReOrgReSubthemeOguls;
		case reSubthemesOgulConstants.CreateReOrgReSubthemeOguls.REQUEST :
			return {
				...state,
				isSending                         : true,
				createReOrgReSubthemeOgulsSuccess : null
			}
		case reSubthemesOgulConstants.CreateReOrgReSubthemeOguls.SUCCESS :
			return {
				...state,
				isSending                         : false,
				createReOrgReSubthemeOgulsSuccess : action.payload.result
			}
		case reSubthemesOgulConstants.CreateReOrgReSubthemeOguls.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				isSending: false
			}
		case reSubthemesOgulConstants.CreateReOrgReSubthemeOguls.CLEAR :
			return {
				...state,
				createReOrgReSubthemeOgulsSuccess : null,
				currentItem                       : {}
			}

			//removeReOrgReSubthemeOguls;
		case reSubthemesOgulConstants.RemoveReOrgReSubthemeOguls.REQUEST :
			return {
				...state,
				isSending                         : true,
				removeReOrgReSubthemeOgulsSuccess : null
			}
		case reSubthemesOgulConstants.RemoveReOrgReSubthemeOguls.SUCCESS :
			return {
				...state,
				isSending                         : false,
				removeReOrgReSubthemeOgulsSuccess : action.payload.result
			}
		case reSubthemesOgulConstants.RemoveReOrgReSubthemeOguls.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				isSending: false
			}
		default :
			return state
	}
}
