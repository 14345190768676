import {
	reThemesOgulConstants
}from './constants'

import i18n from "../localization"
import isRu from "../../../helpers/isRu"

const initialState =
{
	themesOgulChanged : null,
	currentItem       : {},
	error             : null,
	isSending         : false
}

export default function (state = initialState, action){
	switch (action.type){
		case reThemesOgulConstants.GetReThemeOgul.REQUEST:
			return {
				...state,
				currentItem : {},
				isSending   : true
			}
		case reThemesOgulConstants.GetReThemeOgul.SUCCESS:
			return {
				...state,
				currentItem : action.payload.result,
				isSending   : false
			}
		case reThemesOgulConstants.GetReThemeOgul.FAILURE:
			return {
				...state,
				currentItem : {},
				error       : action.payload,
				isSending   : false
			}
		case reThemesOgulConstants.GetReThemeOgul.CLEAR:{
			return {
				...state,
				themesOgulChanged : null,
				isSending         : false,
				currentItem       : {}
			}
		}
		case reThemesOgulConstants.CreateThemesOgul.REQUEST:
		case reThemesOgulConstants.DeleteThemesOgul.REQUEST:
		case reThemesOgulConstants.RestoreThemesOgul.REQUEST:
		case reThemesOgulConstants.UpdateThemesOgul.REQUEST:
			return {
				...state,
				themesOgulChanged : null,
				isSending         : true
			}
		case reThemesOgulConstants.CreateThemesOgul.FAILURE:
		case reThemesOgulConstants.DeleteThemesOgul.FAILURE:
		case reThemesOgulConstants.RestoreThemesOgul.FAILURE:
		case reThemesOgulConstants.UpdateThemesOgul.FAILURE:
			return {
				...state,
				themesOgulChanged : null,
				error             : action.payload,
				isSending         : false
			}
		case reThemesOgulConstants.CreateThemesOgul.SUCCESS:
			return {
				...state,
				themesOgulChanged : action.payload.result ? i18n.messageItemAddSuccses[isRu()] : null,
				isSending         : false
			}
		case reThemesOgulConstants.DeleteThemesOgul.SUCCESS:
			return {
				...state,
				themesOgulChanged : action.payload.result ? i18n.messageItemDeleteSuccses[isRu()] : null,
				isSending         : false
			}
		case reThemesOgulConstants.UpdateThemesOgul.SUCCESS:
			return {
				...state,
				themesOgulChanged : action.payload.result ? i18n.messageItemUpdateSuccses[isRu()] : null,
				isSending         : false
			}
		case reThemesOgulConstants.RestoreThemesOgul.SUCCESS:
			return {
				...state,
				themesOgulChanged : action.payload.result ? i18n.messageItemRestoreSuccses[isRu()] : null,
				isSending         : false
			}
		default:
			return state
	}
}