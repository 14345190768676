export const reTypeApplicantNotificationsConstants = {
    getList: {
		FAILURE : 'GET_LIST_RETYPEAPPLICATIONNOTIFICATION_FAILURE',
		REQUEST : 'GET_LIST_RETYPEAPPLICATIONNOTIFICATION_REQUEST',
		SUCCESS : 'GET_LIST_RETYPEAPPLICATIONNOTIFICATION_SUCCESS',
	},
    getPaged: {
		FAILURE : 'GET_PAGED_RETYPEAPPLICATIONNOTIFICATION_FAILURE',
		REQUEST : 'GET_PAGED_RETYPEAPPLICATIONNOTIFICATION_REQUEST',
		SUCCESS : 'GET_PAGED_RETYPEAPPLICATIONNOTIFICATION_SUCCESS',
	},
    create: {
		FAILURE : 'CREATE_RETYPEAPPLICATIONNOTIFICATION_FAILURE',
		REQUEST : 'CREATE_RETYPEAPPLICATIONNOTIFICATION_REQUEST',
		SUCCESS : 'CREATE_RETYPEAPPLICATIONNOTIFICATION_SUCCESS'
	},
    delete: {
		FAILURE : 'DELETE_RETYPEAPPLICATIONNOTIFICATION_FAILURE',
		REQUEST : 'DELETE_RETYPEAPPLICATIONNOTIFICATION_REQUEST',
		SUCCESS : 'DELETE_RETYPEAPPLICATIONNOTIFICATION_SUCCESS'
	},
    update: {
		FAILURE : 'UPDATE_RETYPEAPPLICATIONNOTIFICATION_FAILURE',
		REQUEST : 'UPDATE_RETYPEAPPLICATIONNOTIFICATION_REQUEST',
		SUCCESS : 'UPDATE_RETYPEAPPLICATIONNOTIFICATION_SUCCESS'
	},
	clear: 'RETYPEAPPLICATIONNOTIFICATION_CLEAR'
}