import { isDuration } from 'moment'
import { i18n } from '../localization'
import {
  reReasonTypesConstants
} from './constants'
import isRu from '../../../helpers/isRu'

const initialState =
{
  isSending: false,
  currentItem: {},
  reasonTypeChanged: null,
  error: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case reReasonTypesConstants.SEND_REREASONTYPES_REQUEST:
      return {
        ...state,
        isSending: true
      }
    case reReasonTypesConstants.SEND_REREASONTYPES_SUCCESS:
      return {
        ...state,
        isSending: false,
        currentItem: action.payload
      }
    case reReasonTypesConstants.SEND_REREASONTYPES_FAILURE:
      return {
        ...state,
        isSending: false,
        error: action.payload
      }
    case reReasonTypesConstants.createReasonType.REQUEST:
    case reReasonTypesConstants.deleteReasonType.REQUEST:
    case reReasonTypesConstants.updateReasonType.REQUEST:
    case reReasonTypesConstants.restoreReasonType.REQUEST:
      return {
        ...state,
        isSending: true,
      }
    case reReasonTypesConstants.createReasonType.FAILURE:
    case reReasonTypesConstants.deleteReasonType.FAILURE:
    case reReasonTypesConstants.updateReasonType.FAILURE:
    case reReasonTypesConstants.restoreReasonType.FAILURE:
      return {
        ...state,
        isSending: false,
        error: action.payload,
      }
    case reReasonTypesConstants.createReasonType.SUCCESS:
      return {
        ...state,
        isSending: false,
        reasonTypeChanged: action.payload.result ? i18n.messageItemAddSuccses[isRu()] : null,
      }
    case reReasonTypesConstants.deleteReasonType.SUCCESS:
      return {
        ...state,
        isSending: false,
        reasonTypeChanged: action.payload.result ? i18n.messageItemDeleteSuccses[isRu()] : null,
      }
    case reReasonTypesConstants.restoreReasonType.SUCCESS:
      return {
        ...state,
        isSending: false,
        reasonTypeChanged: action.payload.result ? i18n.messageItemRestoreSuccses[isRu()] : null,
      }
    case reReasonTypesConstants.updateReasonType.SUCCESS:
      return {
        ...state,
        isSending: false,
        reasonTypeChanged: action.payload.result ? i18n.messageItemUpdateSuccses[isRu()] : null,
      }
    case reReasonTypesConstants.getReasonTypes.CLEAR:
      return {
        ...state,
        isSending: false,
        reasonTypeChanged: null,
        currentItem: {},
      }  
    default:
      return state
  }
}