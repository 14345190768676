import {
	reReportConstants
}from "./constants"
import {
	defAction
}from "../../../../helpers/defaultAction"
import api from "./api"

export const reReportActions = {
	clear,
	createReport,
	deleteReport,
	getAllPaged,
	getAllReports,
	getAllReportsGroup,
	restoreReport,
	updateReport
}

function clear(){
	return (dispatch) => {
		dispatch(request())
		function request(){
			return {
				type: reReportConstants.GetAllPaged.CLEAR
			}
		}
	}
}

function createReport(values){
	const dispatchObj = {
		constants : reReportConstants.CreateReport,
		service   : {
			func   : api.createReport,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function deleteReport(values){
	const dispatchObj = {
		constants : reReportConstants.DeleteReport,
		service   : {
			func   : api.deleteReport,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function getAllPaged(values){
	const dispatchObj = {
		constants : reReportConstants.GetAllPaged,
		service   : {
			func   : api.getAllPaged,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function getAllReports(values){
	const dispatchObj = {
		constants : reReportConstants.GetAllReports,
		service   : {
			func   : api.getAllReports,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function restoreReport(values){
	const dispatchObj = {
		constants : reReportConstants.RestoreReport,
		service   : {
			func   : api.restoreReport,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function updateReport(values){
	const dispatchObj = {
		constants : reReportConstants.UpdateReport,
		service   : {
			func   : api.updateReport,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function getAllReportsGroup(values){
	const dispatchObj = {
		constants : reReportConstants.GetAllReportsGroup,
		service   : {
			func   : api.getAllReportsGroup,
			params : values
		}
	}
	return defAction(dispatchObj)
}
