import {
	reReportHierarchyConstants
}from "./constants"
import {i18n} from "../../localization"
import isRu from "../../../../helpers/isRu"

const initialState = {
	isSending              : false,
	reportHierarchies      : [],
	reportHierarchyChanged : null
}

export default function (state = initialState, action){
	switch (action.type){
		case reReportHierarchyConstants.CreateReportHierarchy.REQUEST :
		case reReportHierarchyConstants.UpdateReportHierarchy.REQUEST :
		case reReportHierarchyConstants.DeleteReportHierarchy.REQUEST :
		case reReportHierarchyConstants.RestoreReportHierarchy.REQUEST :
			return {
				...state,
				isSending              : true,
				reportHierarchyChanged : null
			}
		case reReportHierarchyConstants.CreateReportHierarchy.SUCCESS :
			return {
				...state,
				isSending              : false,
				reportHierarchyChanged : action.payload.result ? i18n.messageItemAddSuccses[isRu()] : null
			}
		case reReportHierarchyConstants.DeleteReportHierarchy.SUCCESS :
			return {
				...state,
				isSending              : false,
				reportHierarchyChanged : action.payload.result ? i18n.messageItemDeleteSuccses[isRu()] : null
			}
		case reReportHierarchyConstants.RestoreReportHierarchy.SUCCESS :
			return {
				...state,
				isSending              : false,
				reportHierarchyChanged : action.payload.result ? i18n.messageItemRestoreSuccses[isRu()] : null
			}
		case reReportHierarchyConstants.UpdateReportHierarchy.SUCCESS :
			return {
				...state,
				isSending              : false,
				reportHierarchyChanged : action.payload.result ? i18n.messageItemUpdateSuccses[isRu()] : null
			}
		case reReportHierarchyConstants.CreateReportHierarchy.FAILURE :
		case reReportHierarchyConstants.UpdateReportHierarchy.FAILURE :
		case reReportHierarchyConstants.DeleteReportHierarchy.FAILURE :
		case reReportHierarchyConstants.RestoreReportHierarchy.FAILURE :
			return {
				...state,
				error                  : action.payload,
				isSending              : false,
				reportHierarchyChanged : null
			}
		case reReportHierarchyConstants.GetAllPaged.REQUEST :
			return {
				...state,
				isSending: true
			}
		case reReportHierarchyConstants.GetAllPaged.SUCCESS :
			return {
				...state,
				isSending         : false,
				reportHierarchies : action.payload.result
			}
		case reReportHierarchyConstants.GetAllPaged.FAILURE :
			return {
				...state,
				error             : action.payload,
				isSending         : false,
				reportHierarchies : []
			}
		case reReportHierarchyConstants.GetAllPaged.CLEAR :
			return {
				...state,
				isSending              : false,
				reportHierarchies      : [],
				reportHierarchyChanged : null
			}
		default :
			return state
	}
}
