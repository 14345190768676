import i18n from '../localization'
import {
  reRequestsStatusConstants
} from './constants'
import isRu from '../../../helpers/isRu'

const initialState =
{
  isSending: false,
  currentItem: {},
  error: null,
  requestStatusChanged: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case reRequestsStatusConstants.SEND_REREQUESTSSTATUS_REQUEST:
      return {
        ...state,
        isSending: true
      }
    case reRequestsStatusConstants.SEND_REREQUESTSSTATUS_SUCCESS:
      return {
        ...state,
        isSending: false,
        currentItem: action.payload
      }
    case reRequestsStatusConstants.SEND_REREQUESTSSTATUS_FAILURE:
      return {
        ...state,
        isSending: false,
        error: action.payload
      }
    case reRequestsStatusConstants.createRequestStatus.REQUEST:
    case reRequestsStatusConstants.deleteRequestStatus.REQUEST:
    case reRequestsStatusConstants.restoreRequestStatus.REQUEST:
    case reRequestsStatusConstants.updateRequestStatus.REQUEST:
      return {
        ...state,
        isSending: true,
        requestStatusChanged: null,
      }
    case reRequestsStatusConstants.createRequestStatus.FAILURE:
    case reRequestsStatusConstants.deleteRequestStatus.FAILURE:
    case reRequestsStatusConstants.restoreRequestStatus.FAILURE:
    case reRequestsStatusConstants.updateRequestStatus.FAILURE:
      return {
        ...state,
        isSending: false,
        error: action.payload,
      }
    case reRequestsStatusConstants.createRequestStatus.SUCCESS:
      return {
        ...state,
        isSending: false,
        requestStatusChanged: action.payload.result ? i18n.messageItemAddSuccses[isRu()] : null,
      }
    case reRequestsStatusConstants.deleteRequestStatus.SUCCESS:
      return {
        ...state,
        isSending: false,
        requestStatusChanged: action.payload.result ? i18n.messageItemDeleteSuccses[isRu()] : null,
      }
    case reRequestsStatusConstants.restoreRequestStatus.SUCCESS:
      return {
        ...state,
        isSending: false,
        requestStatusChanged: action.payload.result ? i18n.messageItemRestoreSuccses[isRu()] : null,
      }
    case reRequestsStatusConstants.updateRequestStatus.SUCCESS:
      return {
        ...state,
        isSending: false,
        requestStatusChanged: action.payload.result ? i18n.messageItemUpdateSuccses[isRu()] : null,
      }
    case reRequestsStatusConstants.SEND_REREQUESTSSTATUS_CLEAR:
      return {
        ...state,
        isSending: false,
        requestStatusChanged: null,
        currentItem: {},
      }  
    default:
      return state
  }
}