export const reReportHierarchyConstants = {
	CreateReportHierarchy: {
		FAILURE : "CREATE_REPORTHIERARCHY_FAILURE",
		REQUEST : "CREATE_REPORTHIERARCHY_REQUEST",
		SUCCESS : "CREATE_REPORTHIERARCHY_SUCCESS"
	},
	DeleteReportHierarchy: {
		FAILURE : "DELETE_REPORTHIERARCHY_FAILURE",
		REQUEST : "DELETE_REPORTHIERARCHY_REQUEST",
		SUCCESS : "DELETE_REPORTHIERARCHY_SUCCESS"
	},
	GetAllPaged: {
		CLEAR   : "CLEAR_REPORTHIERARCHY_ALL_PAGED",
		FAILURE : "GET_REPORTHIERARCHY_All_PAGED_FAILURE",
		REQUEST : "GET_REPORTHIERARCHY_All_PAGED_REQUEST",
		SUCCESS : "GET_REPORTHIERARCHY_All_PAGED_SUCCESS"
	},
	RestoreReportHierarchy: {
		FAILURE : "RESTORE_REPORTHIERARCHY_FAILURE",
		REQUEST : "RESTORE_REPORTHIERARCHY_REQUEST",
		SUCCESS : "RESTORE_REPORTHIERARCHY_SUCCESS"
	},
	UpdateReportHierarchy: {
		FAILURE : "UPDATE_REPORTHIERARCHY_FAILURE",
		REQUEST : "UPDATE_REPORTHIERARCHY_REQUEST",
		SUCCESS : "UPDATE_REPORTHIERARCHY_SUCCESS"
	}
}