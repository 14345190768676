export const reReportConstants = {
	CreateReport: {
		FAILURE : "CREATE_REPORT_FAILURE",
		REQUEST : "CREATE_REPORT_REQUEST",
		SUCCESS : "CREATE_REPORT_SUCCESS"
	},
	DeleteReport: {
		FAILURE : "DELETE_REPORT_FAILURE",
		REQUEST : "DELETE_REPORT_REQUEST",
		SUCCESS : "DELETE_REPORT_SUCCESS"
	},
	GetAllPaged: {
		CLEAR   : "CLEAR_REPORT_ALL_PAGED",
		FAILURE : "GET_REPORT_All_PAGED_FAILURE",
		REQUEST : "GET_REPORT_All_PAGED_REQUEST",
		SUCCESS : "GET_REPORT_All_PAGED_SUCCESS"
	},
	GetAllReports: {
		FAILURE : "GET_REPORTS_All_FAILURE",
		REQUEST : "GET_REPORTS_All_REQUEST",
		SUCCESS : "GET_REPORTS_All_SUCCESS"
	},
	GetAllReportsGroup: {
		FAILURE : "GET_REPORTS_All_GROUP_FAILURE",
		REQUEST : "GET_REPORTS_All_GROUP_REQUEST",
		SUCCESS : "GET_REPORTS_All_GROUP_SUCCESS"
	},
	RestoreReport: {
		FAILURE : "RESTORE_REPORT_FAILURE",
		REQUEST : "RESTORE_REPORT_REQUEST",
		SUCCESS : "RESTORE_REPORT_SUCCESS"
	},
	UpdateReport: {
		FAILURE : "UPDATE_REPORT_FAILURE",
		REQUEST : "UPDATE_REPORT_REQUEST",
		SUCCESS : "UPDATE_REPORT_SUCCESS"
	}
}