import i18n from '../localization'
import isRu from '../../../helpers/isRu'
import {
  reResponsiblesExecutPositionConstants
} from './constants'

const initialState =
{
  isSending: false,
  currentItem: {},
  error: null,
  responsibleExecutPositionChanged: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case reResponsiblesExecutPositionConstants.SEND_RERESPONSIBLESEXECUTPOSITION_REQUEST:
      return {
        ...state,
        isSending: true
      }
    case reResponsiblesExecutPositionConstants.SEND_RERESPONSIBLESEXECUTPOSITION_SUCCESS:
      return {
        ...state,
        isSending: false,
        currentItem: action.payload
      }
    case reResponsiblesExecutPositionConstants.SEND_RERESPONSIBLESEXECUTPOSITION_FAILURE:
      return {
        ...state,
        isSending: false,
        error: action.payload
      }
    case reResponsiblesExecutPositionConstants.createResponsibleExecutPosition.REQUEST:
    case reResponsiblesExecutPositionConstants.deleteResponsibleExecutPosition.REQUEST:
    case reResponsiblesExecutPositionConstants.restoreResponsibleExecutPosition.REQUEST:
    case reResponsiblesExecutPositionConstants.updateResponsibleExecutPosition.REQUEST:
      return {
        ...state,
        isSending: true,
        responsibleExecutPositionChanged: null,
      }
    case reResponsiblesExecutPositionConstants.createResponsibleExecutPosition.FAILURE:
    case reResponsiblesExecutPositionConstants.deleteResponsibleExecutPosition.FAILURE:
    case reResponsiblesExecutPositionConstants.restoreResponsibleExecutPosition.FAILURE:
    case reResponsiblesExecutPositionConstants.updateResponsibleExecutPosition.FAILURE:
      return {
        ...state,
        isSending: false,
        error: action.payload,
      }
    case reResponsiblesExecutPositionConstants.createResponsibleExecutPosition.SUCCESS:
      return {
        ...state,
        isSending: false,
        responsibleExecutPositionChanged: action.payload.result ? i18n.messageItemAddSuccses[isRu()] : null
      }
    case reResponsiblesExecutPositionConstants.deleteResponsibleExecutPosition.SUCCESS:
      return {
        ...state,
        isSending: false,
        responsibleExecutPositionChanged: action.payload.result ? i18n.messageItemDeleteSuccses[isRu()] : null
      }
    case reResponsiblesExecutPositionConstants.restoreResponsibleExecutPosition.SUCCESS:
      return {
        ...state,
        isSending: false,
        responsibleExecutPositionChanged: action.payload.result ? i18n.messageItemRestoreSuccses[isRu()] : null
      }
    case reResponsiblesExecutPositionConstants.updateResponsibleExecutPosition.SUCCESS:
      return {
        ...state,
        isSending: false,
        responsibleExecutPositionChanged: action.payload.result ? i18n.messageItemUpdateSuccses[isRu()] : null
      }
    case reResponsiblesExecutPositionConstants.SEND_RERESPONSIBLESEXECUTPOSITION_CLEAR:
      return {
        ...state,
        isSending:false,
        responsibleExecutPositionChanged: null,
        currentItem: {},
      }  
    default:
      return state
  }
}