import {
	appealsConstants
}from "../../Appeals/store/constants"
import {
	reRegionsConstants
}from "./constants"

const initialState = {
	isSending   : false,
	currentItem : {}
}

export default function (state = initialState, action){
	switch (action.type){
		case appealsConstants.SET_FILTERS :{
			return {
				...state,
				currentItemOld: state.currentItem
			}
		}
		case reRegionsConstants.SEND_REREGIONS_REQUEST:
			return {
				...state,
				isSending: true
			}
		case reRegionsConstants.SEND_REREGIONS_SUCCESS:
			return {
				...state,
				isSending   : false,
				currentItem : action.payload
			}
		case reRegionsConstants.SEND_REREGIONS_FAILURE:
			return {
				...state,
				isSending   : false,
				currentItem : null,
				error       : action.payload
			}
		case reRegionsConstants.CLEAR_REREGIONS:
			return {
				...state,
				isSending   : false,
				currentItem : null
			}
		default:
			return state
	}
}
