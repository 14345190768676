import {
	requests
}from '../../../../api/agent'

export default {
	createReport       : (params) => requests.post('/reReports/create', params),
	deleteReport       : (params) => requests.post('/reReports/delete', params),
	getAllPaged        : (params) => requests.getWithParams('/reReports/getAllPaged', params),
	getAllReports      : (params) => requests.getWithParams('/reReports/getAllReports', params),
	getAllReportsGroup : (params) => requests.getWithParams('/reReports/getAllReportsGroup', params),
	restoreReport      : (params) => requests.post('/reReports/restore', params),
	updateReport       : (params) => requests.post('/reReports/update', params)
}