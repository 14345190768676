export const reSubthemesOgulConstants = {
	SEND_RESUBTHEMESOGUL_REQUEST : "SEND_RESUBTHEMESOGUL_REQUEST",
	SEND_RESUBTHEMESOGUL_SUCCESS : "SEND_RESUBTHEMESOGUL_SUCCESS",
	SEND_RESUBTHEMESOGUL_FAILURE : "SEND_RESUBTHEMESOGUL_FAILURE",
	CLEAR_RESUBTHEMESOGUL        : "CLEAR_RESUBTHEMESOGUL",

	GetSubThemesCountByTheme: {
		REQUEST : "GET_SUBTHEMES_COUNT_BY_THEME_REQUEST",
		SUCCESS : "GET_SUBTHEMES_COUNT_BY_THEME_SUCCESS",
		FAILURE : "GET_SUBTHEMES_COUNT_BY_THEME_FAILURE",
		CLEAR   : "GET_SUBTHEMES_COUNT_BY_THEME_CLEAR"
	},
	GetReOrgReSubthemeOgulsList: {
		REQUEST : "GET_RE_ORG_SUBTHEMES_OGULS_LIST_REQUEST",
		SUCCESS : "GET_RE_ORG_SUBTHEMES_OGULS_LIST_SUCCESS",
		FAILURE : "GET_RE_ORG_SUBTHEMES_OGULS_LIST_FAILURE"
	},
	CreateReSubthemeOguls: {
		REQUEST : "CREATE_SUBTHEMES_OGULS_LIST_REQUEST",
		SUCCESS : "CREATE_SUBTHEMES_OGULS_LIST_SUCCESS",
		FAILURE : "CREATE_SUBTHEMES_OGULS_LIST_FAILURE",
		CLEAR   : "CREATE_SUBTHEMES_OGULS_LIST_CLEAR"
	},
	DeleteReSubthemeOguls: {
		REQUEST : "DELETE_SUBTHEMES_OGULS_LIST_REQUEST",
		SUCCESS : "DELETE_SUBTHEMES_OGULS_LIST_SUCCESS",
		FAILURE : "DELETE_SUBTHEMES_OGULS_LIST_FAILURE"
	},
	RestoreReSubthemeOguls: {
		REQUEST : 'RESTORE_SUBTHEMES_OGULS_LIST_REQUEST',
		SUCCESS : 'RESTORE_SUBTHEMES_OGULS_LIST_SUCCESS',
		FAILURE : 'RESTORE_SUBTHEMES_OGULS_LIST_FAILURE'
	},
	UpdateReSubthemeOguls: {
		REQUEST : 'UPDATE_SUBTHEMES_OGULS_LIST_REQUEST',
		SUCCESS : 'UPDATE_SUBTHEMES_OGULS_LIST_SUCCESS',
		FAILURE : 'UPDATE_SUBTHEMES_OGULS_LIST_FAILURE'
	},
	RemoveReOrgReSubthemeOguls: {
		REQUEST : "REMOVE_RE_ORG_SUBTHEMES_OGULS_LIST_REQUEST",
		SUCCESS : "REMOVE_RE_ORG_SUBTHEMES_OGULS_LIST_SUCCESS",
		FAILURE : "REMOVE_RE_ORG_SUBTHEMES_OGULS_LIST_FAILURE"
	},
	CreateReOrgReSubthemeOguls: {
		REQUEST : "CREATE_RE_ORG_SUBTHEMES_OGULS_LIST_REQUEST",
		SUCCESS : "CREATE_RE_ORG_SUBTHEMES_OGULS_LIST_SUCCESS",
		FAILURE : "CREATE_RE_ORG_SUBTHEMES_OGULS_LIST_FAILURE",
		CLEAR   : "CREATE_RE_ORG_SUBTHEMES_OGULS_LIST_CLEAR"
	}
}
