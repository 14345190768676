import {
	reTypeApplicantNotificationsConstants
}from "./constants"

const initialState = {
	typeApplicantNotificationsPaged : null,
    changeableStatusMessage         : null,
	error                           : null,
	isSending                       : false,
	typeApplicantNotificationsList  : null,
}

export default function (state = initialState, action){
	switch (action.type){
		case reTypeApplicantNotificationsConstants.getList.REQUEST:
			return {
				...state,
				isSending: true,
                typeApplicantNotificationsList : null
			}
		case reTypeApplicantNotificationsConstants.getList.SUCCESS:
			return {
				...state,
				isSending   : false,
			    typeApplicantNotificationsList : action.payload.result
			}
        case reTypeApplicantNotificationsConstants.getPaged.REQUEST:
            return {
                ...state,
                typeApplicantNotificationsPaged: null,
                isSending: true
            }
        case reTypeApplicantNotificationsConstants.getPaged.SUCCESS:
            return {
                ...state,
                isSending   : false,
                typeApplicantNotificationsPaged : action.payload.result
            }

        case reTypeApplicantNotificationsConstants.create.REQUEST:
        case reTypeApplicantNotificationsConstants.update.REQUEST:
        case reTypeApplicantNotificationsConstants.delete.REQUEST:
            return {
                ...state,
                isSending : true,
                changeableStatusMessage : null,

            }
        case reTypeApplicantNotificationsConstants.create.SUCCESS:
        case reTypeApplicantNotificationsConstants.update.SUCCESS:
        case reTypeApplicantNotificationsConstants.delete.SUCCESS:
            return {
                ...state,
                isSending : false,
                changeableStatusMessage : action.payload.result
            }
        case reTypeApplicantNotificationsConstants.create.FAILURE:
        case reTypeApplicantNotificationsConstants.update.FAILURE:
        case reTypeApplicantNotificationsConstants.delete.FAILURE:
        case reTypeApplicantNotificationsConstants.getList.FAILURE:
        case reTypeApplicantNotificationsConstants.getPaged.FAILURE:
            return {
                ...state,
                isSending : false,
                error : action.payload.result,
            }
        case reTypeApplicantNotificationsConstants.clear:
            return {
                ...state,
                typeApplicantNotificationsPaged : null,
                changeableStatusMessage         : null,
                error                           : null,
                isSending                       : false,
                typeApplicantNotificationsList  : null,
            }
		default:
			return state
	}
}
