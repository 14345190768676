import isRu from '../../../helpers/isRu'
import i18n from '../localization'
import {
  reSocialGroupTypesConstants
} from './constants'

const initialState =
{
  isSending: false,
  currentItem: {},
  error: null,
  socialGroupTypeChanged: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case reSocialGroupTypesConstants.SEND_RESOCIALGROUPTYPES_REQUEST:
      return {
        ...state,
        isSending: true
      }
    case reSocialGroupTypesConstants.SEND_RESOCIALGROUPTYPES_SUCCESS:
      return {
        ...state,
        isSending: false,
        currentItem: action.payload
      }
    case reSocialGroupTypesConstants.SEND_RESOCIALGROUPTYPES_FAILURE:
      return {
        ...state,
        isSending: false,
        error: action.payload
      }
    case reSocialGroupTypesConstants.createSocialGroupType.REQUEST:
    case reSocialGroupTypesConstants.deleteSocialGroupType.REQUEST:
    case reSocialGroupTypesConstants.restoreSocialGroupType.REQUEST:
    case reSocialGroupTypesConstants.updateSocialGroupType.REQUEST:
      return {
        ...state,
        isSending: true,
        socialGroupTypeChanged: null,
      }
    case reSocialGroupTypesConstants.createSocialGroupType.FAILURE:
    case reSocialGroupTypesConstants.deleteSocialGroupType.FAILURE:
    case reSocialGroupTypesConstants.restoreSocialGroupType.FAILURE:
    case reSocialGroupTypesConstants.updateSocialGroupType.FAILURE:
      return {
        ...state,
        isSending: false,
        error: action.payload,
      }
    case reSocialGroupTypesConstants.createSocialGroupType.SUCCESS:
      return {
        ...state,
        isSending: false,
        socialGroupTypeChanged: action.payload.result ? i18n.messageItemAddSuccses[isRu()] : null,
      }
    case reSocialGroupTypesConstants.deleteSocialGroupType.SUCCESS:
      return {
        ...state,
        isSending: false,
        socialGroupTypeChanged: action.payload.result ? i18n.messageItemDeleteSuccses[isRu()] : null,
      }
    case reSocialGroupTypesConstants.restoreSocialGroupType.SUCCESS:
      return {
        ...state,
        isSending: false,
        socialGroupTypeChanged: action.payload.result ? i18n.messageItemRestoreSuccses[isRu()] : null,
      }
    case reSocialGroupTypesConstants.updateSocialGroupType.SUCCESS:
      return {
        ...state,
        isSending: false,
        socialGroupTypeChanged: action.payload.result ? i18n.messageItemUpdateSuccses[isRu()] : null,
      }
    case reSocialGroupTypesConstants.SEND_RESOCIALGROUPTYPES_CLEAR :
      return {
        ...state,
        isSending: false,
        socialGroupTypeChanged: null,
        currentItem: {},
      }  
    default:
      return state
  }
}