export const reResponsibilityTypesConstants = {
	SEND_RERESPONSIBILITYTYPES_REQUEST : 'SEND_RERESPONSIBILITYTYPES_REQUEST',
	SEND_RERESPONSIBILITYTYPES_SUCCESS : 'SEND_RERESPONSIBILITYTYPES_SUCCESS',
	SEND_RERESPONSIBILITYTYPES_FAILURE : 'SEND_RERESPONSIBILITYTYPES_FAILURE',
	SEND_RERESPONSIBILITYTYPES_CLEAR   : 'SEND_RERESPONSIBILITYTYPES_CLEAR',
	createReResponsibilityType         : {
		REQUEST : 'CREATE_RERESPONSIBILITYTYPE_REQUEST',
		FAILURE : 'CREATE_RERESPONSIBILITYTYPE_FAILURE',
		SUCCESS : 'CREATE_RERESPONSIBILITYTYPE_SUCCESS'
	},
	deleteReResponsibilityType: {
		REQUEST : 'DELETE_RERESPONSIBILITYTYPE_REQUEST',
		FAILURE : 'DELETE_RERESPONSIBILITYTYPE_FAILURE',
		SUCCESS : 'DELETE_RERESPONSIBILITYTYPE_SUCCESS'
	},
	restoreReResponsibilityType: {
		REQUEST : 'RESTORE_RERESPONSIBILITYTYPE_REQUEST',
		FAILURE : 'RESTORE_RERESPONSIBILITYTYPE_FAILURE',
		SUCCESS : 'RESTORE_RERESPONSIBILITYTYPE_SUCCESS'
	},
	updateReResponsibilityType: {
		REQUEST : 'UPDATE_RERESPONSIBILITYTYPE_REQUEST',
		FAILURE : 'UPDATE_RERESPONSIBILITYTYPE_FAILURE',
		SUCCESS : 'UPDATE_RERESPONSIBILITYTYPE_SUCCESS'
	},
	getList: {
		REQUEST : 'GET_LIST_RERESPONSIBILITY_TYPES_REQUEST',
		FAILURE : 'GET_LIST_RERESPONSIBILITY_TYPES_FAILURE',
		SUCCESS : 'GET_LIST_RERESPONSIBILITY_TYPES_SUCCESS'
	}
}